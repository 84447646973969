import { Network } from "deso-protocol";

export const LOCAL_STORAGE_KEYS = {
  theme: "theme/v1",
  transactionsPerPage: "transactions-per-page/v1",
  blocksPerPage: "blocks-per-page/v1",
};

export enum THEME_OPTIONS {
  lightMode = "light",
  darkMode = "dark",
}

export const DEFAULT_THEME = THEME_OPTIONS.darkMode;

// TODO: Add support for configuring constants based on environment.
export const NODE_URI = "https://validator.safetynetvalidator.nl";
export const GRAPHQL_URI = "https://graphql.safetynet.social/graphql";
export const IDENTITY_URI = "https://identity.deso.org";
export const DESO_NETWORK: Network = "mainnet";
export const COINGECKO_API = "https://api.coingecko.com/api/v3";
export const HEROSWAP_URL = "https://heroswap.com";
export const COINBASE_URL = "https://coinbase.com";
export const DIAMOND_APP_URL = "https://diamondapp.com";
export const WALLET_URL = "https://wallet.deso.com";
export const DESO_REVOLUTION_URL = "https://revolution.deso.com";
export const HEROSWAP_AFFILIATE_NAME = "desoexplorer";

export const MEMPOOL_LABEL = "Mempool...";
export const MEMPOOL_AGE = "Unconfirmed...";

export const MOBILE_WIDTH_BREAKPOINT = 768;
export const EPOCHS_UNTIL_UNLOCKABLE = 3; // TODO: fetch this from global params

// We retry every 1 second until we reach the max retries.
export const TXN_FINALITY_POLL_RETRIES = 30;

export const VALIDATOR_REGISTRATION_ANNOUNCEMENTS_PASSWORD_KEY =
  "validator-registration-announcements-password";
export const VALIDATOR_REGISTRATION_ANNOUNCEMENTS_PASSWORD =
  "decentralization is key";
export const VALIDATOR_REGISTRATION_DISCUSSION_PASSWORD_KEY =
  "validator-registration-discussion-password";
export const VALIDATOR_REGISTRATION_DISCUSSION_PASSWORD =
  "welcome to the revolution";
